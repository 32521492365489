<template>
  <div class="page1">
    <EButton type="primary" @click="dialogVisible=true">增加</EButton>
    <ETable :tableTitle="tableTitle" :tableData="tableData" :needPagination="false">
      <el-table-column fixed="right" label="操作" width="180px">
        <template slot-scope="scope">
          <div>
            <EButton type="text" @click="showDialog(scope.row)">
              修改
            </EButton>
            <EButton type="text" @click="remove(scope.row)">
              删除
            </EButton>
            <EButton type="text" @click="change(scope.row.areaId,scope.row.status)">
              {{scope.row.status==1?'禁用':'启用'}}
            </EButton>
          </div>
        </template>
      </el-table-column>
    </ETable>
    <EDialog :dialogVisible="dialogVisible" :title="dialogForm.areaId?'修改区域':'新增区域'" @handleClose="cancelDialog" width="30%"  @handleClick="saveData(dialogForm.areaId)"
    :disabled="saveDisabled">
      <EForm :formColumns="dialogFormColumns" :rowSize="1" :optionsBtn="false" :actionBtn="true" :formData="dialogForm" ref="form" :searchFlag="false" @handleClose="cancelDialog" :labelPosition="labelPosition" :labelWidth="labelWidth" :formRules="formRules">
      </EForm>
    </EDialog>
  </div>
</template>

<script>
import ETable from '@/components/ETable'
import Http from "@/service/http";
import EDialog from '@/components/EDialog'
import EButton from '@/components/EButton'
import EForm from '@/components/EForm'
import { identity_type, status } from '@/assets/js/config'
import vxRule from "@/assets/js/formValidate";
import {otherMixin} from "@/components/mixin";

export default {
  name: 'Login',
  mixins:[otherMixin],
  data () {
    return {
      tableTitle: [
        {
          label: '编号',
          prop: 'areaId'
        },
        {
          label: '区域名称',
          prop: 'areaName'
        },
        {
          label: '新建时间',
          prop: 'createTime',
        },
        {
          label: '新建人',
          prop: 'createUserCn'
        },
        {
          label: '修改时间',
          prop: 'updateTime'
        },
        {
          label: '状态',
          prop: 'statusCn'
        },
        {
          label: '备注',
          prop: 'remark'
        },
      ],
      tableData: [],
      searchForm: {},
      count: null,
      dialogFormColumns: [
        {
          title: '区域名称：',
          type: 'text',
          property: 'areaName',
          placeHolder: '最多可录入20字',
          show: true,
        },
        // {
        //   title: '位置：',
        //   type: 'select',
        //   property: 'placeId',
        //   placeHolder: '请选择',
        //   show: true,
        //   options: [],
        //   checkStrictly: false
        // },
      ],
      exportData: {},
      dialogVisible: false,
      labelPosition: 'right',
      labelWidth: '100px',
      formRules: {
        areaName: vxRule(true, '', "blur", "区域名称不能为空"),
        // placeId: vxRule(true, '', "change", "位置不能为空"),
      },
      dialogForm: {
        areaName: '',
        placeId: '',
        areaId:'',
      },
    }
  },
  watch: {},
  created () {
    this.getData()

  },
  components: { ETable, EDialog, EButton, EForm },
  beforeMount () {

  },
  methods: {
    async changeStatus(id,status){
      let res=await Http.cmareaUpdStatus({id,status})
      if(res.code==200){
        this.$message.success(res.msg)
        this.getData()
      }
    },
    change(id, status) {
      let msg = ''
      if (status == 1) {
        msg = '禁用'
      } else {
        msg = '启用'
      }
      this.$messageBox.confirm('确定' + msg + '？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(res => {
        this.changeStatus(id, status==1?0:1)
      }).catch(res => {

      });
    },
    cancelDialog () {
      this.dialogVisible = false
      this.dialogForm = {
        areaName: '',
        placeId: '',
        areaId: '',
      }
      this.$refs.form.$refs.form.resetFields()
    },
    showDialog (row) {
      this.dialogVisible = true
      this.dialogForm.areaName = row.areaName
      this.dialogForm.placeId = row.placeId
      this.dialogForm.areaId = row.areaId
    },
    saveData (areaId) {
      this.$refs['form'].$refs['form'].validate((valid, object) => {
        if (valid) {
          this.areaSubmit(areaId)
        } else {
          return false;
        }
      });
    },
    async getData () {
      let res = await Http.getAreaList()
      if (res.code == 200) {
        this.tableData = res.data
      }
    },
    //新增或者修改请求
    async areaSubmit (areaId) {
      this.setDisabled(true)
      let params = {
        areaName: this.dialogForm.areaName,
        // placeId: this.dialogForm.placeId
      }
      if (areaId) {
        params.areaId = areaId
      }
      let res = await Http.areaSubmit(params)
      if (res.code == 200) {
        this.$message.success(res.msg);
        this.dialogVisible = false
        this.setDisabled(false)
        this.cancelDialog()
        this.getData()
      }
    },
    remove (row) {
      this.$messageBox.confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.areaRemove(row.areaId)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    async areaRemove (areaId) {
      let res = await Http.areaRemove({ ids: areaId })
      if (res.code == 200) {
        this.$message.success(res.msg)
        this.getData()
      }
    },
    //获取位置下拉
    async getPlaceLabel(){
      let res = await Http.getPlaceLabel()
      if (res.code == 200) {
        this.dialogFormColumns[1].options = res.data
      }
    }
  }
}
</script>

<style lang="scss">
</style>
